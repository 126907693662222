import React from "react";
import { Link } from "react-router-dom";
import "./CustomMenu.css";

const CustomMenu = ({ menuItems }) => {
	const handleMouseEnter = (event) => {
		const dropdown = event.currentTarget.querySelector(".dropdown");
		if (dropdown) {
			dropdown.style.display = "block";
		}
	};

	const handleMouseLeave = (event) => {
		const dropdown = event.currentTarget.querySelector(".dropdown");
		if (dropdown) {
			dropdown.style.display = "none";
		}
	};

	const renderMenuItem = (item) => {
		if (item.children) {
			return (
				<div
					key={item.key}
					className="menu-item has-dropdown"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}>
					<button className="menu-button">
						{item.icon}
						<span>{item.label}</span>
						<svg className="dropdown-arrow" viewBox="0 0 24 24">
							<path d="M7 10l5 5 5-5z" />
						</svg>
					</button>
					<div className="dropdown">{renderDropdown(item.children)}</div>
				</div>
			);
		}

		return (
			<Link
				key={item.key}
				to={
					item.key === "5"
						? "/cart"
						: item.key === "6"
						? "/wishlist"
						: item.key === "7"
						? "/profile"
						: "/"
				}
				className="menu-item">
				{item.icon}
				<span>{item.label}</span>
			</Link>
		);
	};

	const renderDropdown = (items) => {
		return (
			<div className="dropdown-content">
				{items.map((subItem) => {
					if (subItem.children) {
						return (
							<div key={subItem.key} className="dropdown-item has-dropdown">
								<button
									className="dropdown-button"
									>
									{subItem.label}
									<svg className="dropdown-arrow" viewBox="0 0 24 24">
										<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
									</svg>
								</button>
								<div className="sub-dropdown">
									{renderDropdown(subItem.children)}
								</div>
							</div>
						);
					}

					return (
						<button
							key={subItem.key}
							className="dropdown-item"
							>
							{subItem.label}
						</button>
					);
				})}
			</div>
		);
	};

	return (
		<nav className="custom-menu-head">
			<div className="menu-container">{menuItems.map(renderMenuItem)}</div>
		</nav>
	);
};

export default CustomMenu;
