import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, message, Pagination, Input, Result, Skeleton } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import queryString from "query-string";
import "./Books.css";

const { Search } = Input;

const Books = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { apiurl, token } = useAuth();

	const [filteredBooks, setFilteredBooks] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [age, setAge] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalBooks, setTotalBooks] = useState(0);
	const [wishlist, setWishlist] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchBooks = async (params = {}) => {
		setLoading(true);
		try {
			const query = queryString.stringify(params);
			const response = await fetch(`${apiurl}/test/books/?${query}`);
			if (!response.ok) throw new Error(`Error: ${response.statusText}`);
			const data = await response.json();
			setTotalBooks(data.count);
			setFilteredBooks(data.results.data);
		} catch (error) {
			message.error("Error fetching books.");
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}
	};

	const fetchWishlist = async () => {
		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (!response.ok) throw new Error(`Error: ${response.statusText}`);
			const data = await response.json();
			setWishlist(data.data);
		} catch (error) {
			console.error("Error fetching wishlist:", error);
		}
	};

	useEffect(() => {
		const params = new URLSearchParams(location.search);

		const search = params.get("search") || "";
		const page = parseInt(params.get("page"), 10) || 1;
		const size = parseInt(params.get("pageSize"), 10) || 10;
		const age = params.get("age") || "";

		console.log(age);
		const category = params.get("category")?.split(",") || [];

		setSearchTerm(search);
		setCurrentPage(page);
		setPageSize(size);
		setAge(age);
		setSelectedCategory(category);

		fetchBooks({ search, page, pageSize: size, category, age: age });
	}, [location.search]);

	const handleSearch = (value) => {
		setLoading(true);
		setSearchTerm(value);
		setCurrentPage(1);

		const params = new URLSearchParams({
			search: value,
			page: 1,
			pageSize,
			age,
			category: selectedCategory.join(","),
		});
		navigate(`${location.pathname}?${params.toString()}`);

		fetchBooks({
			search: value,
			page: 1,
			pageSize,
			category: selectedCategory,
			age: age,
		}).finally(() => {
			setLoading(false);
		});
	};

	const handlePageChange = (page, size) => {
		setCurrentPage(page);
		setPageSize(size);

		const params = new URLSearchParams({
			search: searchTerm,
			page,
			age,
			pageSize: size,
			category: selectedCategory,
		});
		navigate(`${location.pathname}?${params.toString()}`);

		fetchBooks({
			search: searchTerm,
			page,
			pageSize: size,
			category: selectedCategory,
			age: age,
		});
	};

	const renderBookActions = (bookId) => (
		<>
			<Button onClick={() => navigate(`/catalogue/${bookId}`)}>
				View Book
			</Button>
			<Button
				type="primary"
				icon={isWishlisted(bookId) ? <HeartFilled /> : <HeartOutlined />}
				onClick={() => toggleWishlist(bookId)}
			/>
		</>
	);

	const isWishlisted = (bookId) =>
		wishlist.some((item) => item.book.id === bookId);

	const toggleWishlist = async (bookId) => {
		setLoading(true);
		const wishlisted = isWishlisted(bookId);
		const method = wishlisted ? "DELETE" : "POST";
		if (!token) {
			setLoading(false);
			return message.error("Please login to wishlist books.");
		}
		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				method,
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: bookId }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchWishlist();
				setLoading(false);
			} else {
				setLoading(false);
				message.error("Please login to add to wishlist.");
			}
		} catch (error) {
			setLoading(false);
			message.error("Error toggling wishlist.");
		}
	};

	if (loading) {
		return (
			<div className="books-container">
				<div className="skeleton-container">
					{Array.from({ length: 10 }).map((_, index) => (
						<div key={index} className="skeleton-item">
							<Skeleton.Avatar active size="large" shape="square" />
							<Skeleton active paragraph={{ rows: 4 }} />
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="books-container">
			<div className="mobile-filter-btn">
				<Search
					placeholder="Search books"
					onSearch={handleSearch}
					onChange={(e) => setSearchTerm(e.target.value)}
					value={searchTerm}
				/>
			</div>
			<div className="book-list">
				<div className="book-grid">
					{filteredBooks.length > 0 ? (
						filteredBooks.map((book) => (
							<div key={book.id} className="book-item">
								<div className="book-image">
									<img src={`${apiurl}${book.image}`} alt={book.title} />
								</div>
								<div className="book-details">
									<div>
										<div className="book-title">
											{book.title.length > 30
												? `${book.title.slice(0, 30)}...`
												: book.title}
										</div>
										<div className="book-categories">
											{book.categories.length > 0
												? book.categories
														.map((category) => category.name)
														.join(", ")
												: "No categories"}
										</div>
										<div className="book-author">By {book.author}</div>
										<div className="desc">
											{book.description.slice(0, 120)}...
										</div>
									</div>
									<div className="book-actions">
										{renderBookActions(book.id)}
									</div>
								</div>
							</div>
						))
					) : (
						<div className="books-nt-available">
							<Result
								status="404"
								title="Books Not Available"
								subTitle="Sorry, there are no books available at the moment."
							/>
						</div>
					)}
				</div>
				<Pagination
					className="book-pagination"
					current={currentPage}
					pageSize={pageSize}
					total={totalBooks}
					onChange={handlePageChange}
					showSizeChanger
					pageSizeOptions={["10", "20", "50"]}
				/>
			</div>
		</div>
	);
};

export default Books;
