import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/Authentication/Login";
import Signup from "./components/Authentication/Signup";
import ForgotPassword from "./components/Forgotpassword/Forgot";
import ResetPasswordForm from "./components/Forgotpassword/Reset";
import Success from "./components/Forgotpassword/Success";
import { AdminAuthRoute } from "./components/utils/AdminAuthRoute";
import { AuthRoute } from "./components/utils/AuthRoute";
import BookDetail from "./components/User/BookDetails";
import AdminBookDetail from "./components/Management/BookDetails";
import Subscriptionplans from "./components/Management/Subscriptionplans";
import AddActivity from "./components/Management/AddActivity";
import Cart from "./components/User/Cart";
import RentBook from "./components/User/RentBook";
import UserOrders from "./components/User/Order";
import UserOrderDetails from "./components/User/OrderDetails";
import RentAll from "./components/User/RentAll";
import AdminOrders from "./components/Management/Orders";
import AdminOrderDetails from "./components/Management/OrderDetails";
import { DpAuthRoute } from "./components/utils/DpAuthRoute";
import DpBookDetail from "./components/DeliveryPartner/BookDetails";
import DpOrders from "./components/DeliveryPartner/Orders";
import DpOrderDetails from "./components/DeliveryPartner/OrderDetails";
import DisplayActivities from "./components/Management/DisplayActivities";
import Activity from "./components/Management/Activity";
import WishList from "./components/User/Wishlist";
import MyBooks from "./components/User/Mybooks";
import UserReturns from "./components/Management/Return";
import AdminReturnDetails from "./components/Management/ReturnDetails";
import DpReturns from "./components/DeliveryPartner/Return";
import DpReturnDetails from "./components/DeliveryPartner/ReturnDetails";
import Layout from "./components/User/Layout";
import Newsletter from "./components/Management/Newsletter";
import Main2 from "./components/Management/Layout";
import UserActivity from "./components/User/UserActivity";
import ActivityDetail from "./components/User/ActivityDetail";
import UsersInfo from "./components/Management/UsersInfo";
import Subscriptions from "./components/User/Subscriptions";
import Profile from "./components/User/Profile";
import Home from "./components/User/Home";
import CopounCodes from "./components/Authentication/CopounCodes";
import Catelogue from "./components/User/Catelogue";
import TermsAndConditions from "./components/User/TermsAndConditions";
import PrivacyPolicy from "./components/User/PrivacyPolicy";
import CancellationPolicy from "./components/User/Cancellation";
import MainInventory from "./components/Management/MainInventory";
import OfferMain from "./components/Management/OfferMain";
import AdminHome from "./components/Management/Home";
import Payments from "./components/Management/Payments";
import CommentList from "./components/Management/Reviews";
import { useEffect } from "react";

function App() {
	const location = useLocation();
	useEffect(() => {
		const scrollableContainer = document.querySelector(".user-layout-content");
		if (scrollableContainer) {
			scrollableContainer.scrollTo(0, 0);
		}
	}, [location]);
	return (
		<Routes>
			<Route path="/" element={<Home />}></Route>
			<Route path="/login" element={<Login />} />
			<Route path="/catalogue/:id" element={<BookDetail />}></Route>
			<Route
				path="/reset/:uidb64/:token"
				element={<ResetPasswordForm />}></Route>
			<Route path="/forgot" element={<ForgotPassword />}></Route>
			<Route
				path="/termsandconditions"
				element={<TermsAndConditions />}></Route>
			<Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
			<Route
				path="/cancellationpolicy"
				element={<CancellationPolicy />}></Route>
			<Route path="/signup" element={<Signup />} />
			<Route path="/catalogue" element={<Catelogue />}></Route>
			<Route path="/activities" element={<UserActivity />}></Route>
			<Route path="/subscriptions" element={<Subscriptions />}></Route>
			<Route path="/copouncode" element={<CopounCodes />}></Route>
			<Route path="/activities/:id" element={<ActivityDetail />}></Route>
			<Route path="/" element={<AdminAuthRoute />}>
				<Route path="/inventory/book/:id" element={<AdminBookDetail />}></Route>
				<Route path="/offers" element={<OfferMain />}></Route>
				<Route
					path="/subscriptionplans"
					element={<Subscriptionplans />}></Route>
				<Route path="/admin/activities/add" element={<AddActivity />}></Route>
				<Route path="/admin/users" element={<UsersInfo />}></Route>
				<Route path="/admin/reviews" element={<CommentList />}></Route>
				<Route path="/admin/home" element={<AdminHome />}></Route>
				<Route path="/admin/payments" element={<Payments />}></Route>
				<Route path="/inventory" element={<MainInventory />}></Route>
				<Route
					path="/admin/activities"
					element={
						<Main2>
							<DisplayActivities />
						</Main2>
					}></Route>
				<Route path="/admin/activities/:id" element={<Activity />}></Route>
				<Route path="/admin/orders" element={<AdminOrders />}></Route>
				<Route path="/newsletter" element={<Newsletter />}></Route>
				<Route path="/admin/returns" element={<UserReturns />}></Route>
				<Route path="/admin/orders/:id" element={<AdminOrderDetails />}></Route>
				<Route path="/returns/:id" element={<AdminReturnDetails />}></Route>
			</Route>
			<Route path="/" element={<DpAuthRoute />}>
				<Route path="/delivery/books/:id" element={<DpBookDetail />}></Route>
				<Route path="/delivery/orders" element={<DpOrders />}></Route>
				<Route path="/delivery/orders/:id" element={<DpOrderDetails />}></Route>
				<Route path="/delivery/returns" element={<DpReturns />}></Route>
				<Route
					path="/delivery/returns/:id"
					element={<DpReturnDetails />}></Route>
			</Route>
			<Route path="/" element={<AuthRoute />}>
				<Route path="/mybooks" element={<MyBooks />}></Route>

				<Route path="/cart" element={<Cart />}></Route>
				<Route path="/wishlist" element={<WishList />}></Route>
				<Route path="/layout" element={<Layout />}></Route>
				<Route path="/profile" element={<Profile />}></Route>
				<Route path="/change/success" element={<Success />}></Route>
				<Route path="/rent/all" element={<RentAll />}></Route>
				<Route path="/rent/:id" element={<RentBook />}></Route>
				<Route path="/orders" element={<UserOrders />}></Route>
				<Route path="/orders/:id" element={<UserOrderDetails />}></Route>
			</Route>
		</Routes>
	);
}

export default App;
